import httpClient from './httpClient';

export default {
  async list_games() {
    const { data } = await httpClient({ requiresAuth: false }).get('/list-game')
    return data? data:false
  },
  async login_to_sexy_lobby(username) {
    const { data } = await httpClient({ requiresAuth: false }).get('/login-sexy/'+username)
    
    return data? data:false
  }
}