<template>
  <div class="index-container" style="min-height: 100vh">
    <div style="padding: 1rem;padding-top: 0;">
      <div class="games-container background-panel">
        <div style="display: flex;align-items: center;flex-direction: column;">
          <div style="font-size: 18px;cursor: pointer;white-space: nowrap;">
            <span>LIVE</span><span class="blink-orange"> - ไลฟ์คาสิโน </span>
          </div>
          <div style="font-size: 9px">
            <span class="blink-orange">รางวัลล่าสุด! </span><span>{{random_users}}</span>
          </div>
        </div>

        <div v-if="game_list.length" style="display: flex; justify-content: space-evenly;padding: 0.5rem">
            <button :class="`highlight-primary th ${selected_game_group == name? 'active':''}`" v-for="name in game_group" :key="name.key" @click="selected_game_group = name"><p style="font-size: 20px">{{name}}</p></button>
        </div>

        <div class="game-list background-body">
          <div @click="goto_game(GameCode)" v-for="({ GameCode, Image1 }, i) in game_list.filter(({GameType}) => GameType == selected_game_group)" :key="i.key" >
            <img class="game-icon" :src="Image1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GameService from '../../services/GameService'

export default {
  name: 'game-page',
  data () {
    return {
      video_url: 'https://cdn.discordapp.com/attachments/970675221285707786/973150163239591946/pexels-taryn-elliott-5309381.mp4',
      game_list: [],
      game_group: [],
      selected_game_group: null,
      game_url: 'https://www.gwp6868.net/PlayGame?',
      motto: 'ฝากถอนโอนไวอุ่นใจไม่มีโกง ไม่ต้องนั่งเล่นเป็นชั่วโมง เล่นแปปเดียวก็แตกได้ ก็เว็บนี้มันแตกง่าย...UDAY ตอง8 ไม่ต้องนั่ง Sad เรามีแต่ Happy..จะเอา Super Car เอา Ferrari มาเล่นที่นี่',
      motto2: 'ฝากถอน ออโต้ ไม่มีโกง',
      random_users: '',
    }
  },
  methods: {
    nav_goto(path) {
      try {
        this.$router.push(path)
      } catch (err) {
        console.log(err);
      }
    },
    async init_game_list() {
      try {
        this.game_list = await GameService.list_games() 
        this.game_group = Array.from(new Set(this.game_list.map(({ GameType }) => GameType)))
        this.selected_game_group = this.game_group[1]
      } catch (err) {
        console.log(err);
      }
    },
    goto_game(gameCode) {
      var isMobile = true
      if (typeof screen.orientation !== 'undefined') {
        isMobile = false;
      }
      const url = this.game_url+`token=${localStorage.getItem('user')}&appid=TGZU&gameCode=${gameCode}&language=en&mobile=${isMobile}&redirectUrl=http://uday888.com`
      window.location.href = url
    },
    init_random_user() {
      const vue = this
      setInterval(() => {
        vue.random_users = vue.game_list[Math.floor(Math.random() * (vue.game_list.length-1))].GameName
      },1500)
    },
    check_user() {
      if (!localStorage.getItem('user')) {
        this.$router.push('/register')
      }
    }
  },
  async beforeMount() {
    await this.init_game_list()
    this.init_random_user()
    this.check_user()
  }
}
</script>

<style lang="less" scoped>
  .active {
    background-color: #fce285;
    color: white
  }
  .game-icon {
    height: 250px;
    background-color: #18222c;
    border: 3px solid #fce285;
    box-shadow: 0 0 20px rgb(0 0 0);
  }
  .slider-text {
    animation: marquee 45s linear infinite;
  }
  .slider-container {
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  .header {
    margin-top: 2rem;
  }
  .index-container::-webkit-scrollbar {
    display: none
  }
  .blink-orange {
    animation-name: blink-orange;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
  }
  @keyframes blink-orange {
    0% {
      color: #eeeeee
    }
    100% {
      color: #fde383
    }
  }
  .video-container {
    position: relative;
  }
  .games-container {
    padding: 0.5rem 1rem;
    text-align: start;
    font-size: 2em;
    border-radius: 5px;
    margin: 1rem 0;
    border: 1px solid #fde383;
    span {
      font-weight: bold;
    }
  }
  .game-list {
    margin: 0.25em;
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    justify-content: space-evenly;
    padding: 0.5rem;
    border-radius: 5px;

    img {
      margin: 0.25em;
      cursor: pointer;
      border-radius: 0.25em;
    }
  }
  .game-list::-webkit-scrollbar {
    display: none;
  }
  @keyframes marquee {
    0% {
      transform: translate(50%, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
</style>
